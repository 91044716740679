import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import { createTheme } from "@mui/material/styles";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { GlobalSizes } from "./size";
import { WsContextProvider } from "./ws-context";
import { ClientContextProvider } from "./client-context";

function Root() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const brinkerTheme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "Montserrat",
        },
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#6c73f0",
          },
          secondary: {
            main: "#e93b52",
          },
        },
        components: {
          MuiContainer: {
            styleOverrides: {
              root: {
                margin: GlobalSizes.mediumGap,
              },
            },
          },
          //Table
          MuiTable: {
            styleOverrides: {
              root: {
                backgroundColor: prefersDarkMode ? "" : "#f5f5f5",
                verticalAlign: "top",
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                verticalAlign: "top",
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <React.StrictMode>
      <Auth0Provider domain="auth0.brinker.ai" clientId="2vJEkFQ3oLO87u4owY8XUWLR6vmY3rKw" authorizationParams={{ redirect_uri: window.location.origin }}>
        <ThemeProvider theme={brinkerTheme}>
          <GlobalStyles styles={{ "*": { fontFamily: brinkerTheme.typography.fontFamily } }} />
          <WsContextProvider>
            <ClientContextProvider>
              <App />
            </ClientContextProvider>
          </WsContextProvider>
          <CssBaseline />
        </ThemeProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Root />);
