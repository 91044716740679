import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { InvestigationSizes, GlobalSizes } from "../../size";
import { TwitterTweetEmbed } from "react-twitter-embed";
import html2canvas from "html2canvas";
import LinkButton from "../LinkButton";
import { labels } from "../../labels";
import { LikingUsersType, LikingUserType, MentionType, TwitterUserType } from "../../state";

function avatarWithEngagement(user: LikingUserType) {
  let emoji = "👍";
  if (user.engagement_type === "retweeted_by") emoji = "🔁";
  if (user.engagement_type === "like_retweet") emoji = "👍🔁";
  return (
    <Badge key={user.id} overlap="circular" badgeContent={emoji}>
      <Avatar alt={user.name} src={user.profile_image_url.replace("_normal", "")} />
    </Badge>
  );
}

function likerGroups(likingUserGroups?: LikingUsersType) {
  if (!likingUserGroups) {
    return <Box sx={{ pb: GlobalSizes.gap }}>No liking users found.</Box>;
  }

  const elements = [];
  const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;
  for (const [key, value] of Object.entries(likingUserGroups)) {
    const likingByKey = key;
    const likingByValues = value;
    elements.push(<h4 key={likingByKey}>{likingByKey.replace("_", " ")}</h4>);
    for (const group of likingByValues) {
      const groupKey = group[0];
      const isValidDate = dateRegex.test(groupKey);
      const userElements = [];
      if (likingByKey === "by_date" && !isValidDate) {
        continue;
      }
      const groupValues = group[1];
      const avatars = [];

      for (const user of groupValues) {
        const userDetails = `${user.verified ? "(Verified)" : "(Unverified)"} • ${user.public_metrics.followers_count} followers / ${
          user.public_metrics.following_count
        } following • ${user.public_metrics.tweet_count} tweets • created ${user.created_at}`;
        userElements.push(
          <ListItem key={user.id}>
            <ListItemIcon>
              <a target="_blank" rel="noreferrer" href={`https://twitter.com/${user.username}`}>
                {avatarWithEngagement(user)}
              </a>
            </ListItemIcon>
            <ListItemText primary={"@" + user.username + " - " + user.name + " - " + user.description} secondary={userDetails} />
          </ListItem>
        );
        avatars.push(avatarWithEngagement(user));
      }
      elements.push(
        <Accordion key={groupKey}>
          <AccordionSummary expandIcon={<AvatarGroup max={6}>{avatars}</AvatarGroup>} aria-controls="panel2a-content" id={groupKey}>
            {groupKey}
          </AccordionSummary>
          <AccordionDetails>
            <List dense>{userElements}</List>
          </AccordionDetails>
        </Accordion>
      );
    }
  }
  return elements;
}

interface TwitterDataProps {
  twitterUser: TwitterUserType;
  postsDropDown: JSX.Element;
  alert: MentionType;
  cardRef: React.RefObject<HTMLDivElement>;
}

function TwitterData({ twitterUser, postsDropDown, alert, cardRef }: TwitterDataProps) {
  const followersRatio = twitterUser.public_metrics.followers_count / twitterUser.public_metrics.following_count;
  const followersRatioString = followersRatio.toFixed(2);
  const tweetId = alert?.twitter_data?.data?.id;

  const captureScreenshot = async () => {
    if (cardRef.current && alert?.twitter_data?.data?.id) {
      const canvas = await html2canvas(cardRef.current);
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `screenshot_${twitterUser.username}_${alert?.twitter_data.data.id}.png`;
      link.click();
    }
  };

  return (
    <div>
      <Card sx={{ display: "flex", mt: GlobalSizes.gap }}>
        <CardMedia
          component="img"
          sx={{ width: GlobalSizes.largeHeight }}
          height={GlobalSizes.largeHeight}
          image={twitterUser.profile_image_url.replace("_normal", "")}
          title={twitterUser.username}
        />

        <CardContent>
          <List dense>
            <ListItem>
              <ListItemText primary={"@" + twitterUser.username} secondary={twitterUser.verified ? "(Verified)" : "(Unverified)"} />
              <a href={`https://twitter.com/${twitterUser.username}`} target="_blank" rel="noreferrer">
                <Button sx={{ ml: GlobalSizes.gap }} variant="outlined">
                  Show in twitter
                </Button>
              </a>
            </ListItem>
            <ListItem>
              <ListItemText primary={twitterUser.description} secondary="Biography" />
            </ListItem>
            <ListItem>
              <ListItemText primary={twitterUser.created_at} secondary="User created" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${twitterUser.public_metrics.followers_count} / ${twitterUser.public_metrics.following_count} ${
                  followersRatio !== Infinity ? `= ${followersRatioString}` : ""
                }`}
                secondary="followers/following"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={twitterUser.public_metrics.tweet_count} secondary="Tweets" />
            </ListItem>
          </List>
          <LinkButton destination={`/action?url=${encodeURIComponent(alert.url)}`} label={labels.takeAction.title} />
        </CardContent>
      </Card>

      {postsDropDown}
      <Stack direction={"row"} alignItems={"self-start"}>
        <div style={{ width: InvestigationSizes.twitterEmbedDivWidth }}>{tweetId && <TwitterTweetEmbed key={tweetId} tweetId={tweetId} />}</div>
        <Button variant="outlined" onClick={captureScreenshot} sx={{ mt: GlobalSizes.gap }}>
          Screenshot
        </Button>
      </Stack>
      <h3>Users who liked (👍) or reposted (🔁) by group:</h3>
      {likerGroups(alert?.twitter_data?.liking_users)}
    </div>
  );
}

export default TwitterData;
