import { Typography, Container, Box } from "@mui/material";
import Bar from "../Components/Bar";
import { labels, severity } from "../labels";
import { Link } from "react-router-dom";
import { useWsContext } from "../ws-context";
import InquiriesCharts from "../Components/InquiriesCharts";
import { pagesContainerMargins } from "../size";
import { useCallback, useMemo } from "react";

function Home() {
  const { mentionsState, cachedMentions } = useWsContext();

  const flaggedAlerts = useMemo(() => mentionsState.filter((m) => m.flagged), [mentionsState]);
  const linksNotCollectedManually = useMemo(() => flaggedAlerts.filter((mention) => mention.collected_manually), [flaggedAlerts]);
  const investigationsInProgress = useMemo(() => {
    const investigations = new Set<string>();
    mentionsState
      .filter((m) => m.parent_mention)
      .forEach((alert) => {
        if (!alert.parent_mention) return;
        investigations.add(alert.parent_mention);
      });
    return Array.from(investigations);
  }, [mentionsState]);

  const activeMentions = useMemo(() => mentionsState.filter((mention) => !mention.hidden), [mentionsState]);

  const sortedSeverities = useMemo(() => {
    const severityCount: { [key: string]: number } = {};
    activeMentions.forEach((mention) => {
      const severityLevel = mention?.severity?.toLowerCase();
      if (severityLevel) severityCount[severityLevel] = (severityCount[severityLevel] || 0) + 1;
    });

    return Object.entries(severityCount).sort((a, b) => (severity[b[0].toUpperCase()]?.value || 0) - (severity[a[0].toUpperCase()]?.value || 0));
  }, [activeMentions]);

  const alertsFromLastDay = useCallback(() => {
    const last24Hours = new Date().getTime() - 24 * 60 * 60 * 1000;
    const mentionsFromLast24Hours = activeMentions?.filter((mention) => new Date(mention.detection_date || 0).getTime() > last24Hours);
    return mentionsFromLast24Hours;
  }, [activeMentions]);

  const activeCachedMentions = useMemo(() => cachedMentions.filter((mention) => !mention.hidden), [cachedMentions]);

  return (
    <div>
      <Bar label={labels.home.title} />
      <Container sx={{ ...pagesContainerMargins }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Brinker.ai
        </Typography>
        {activeCachedMentions.length > 0 && <InquiriesCharts mentions={activeCachedMentions} />}
        <Box>
          <Box p={1}>
            <Typography variant="h6" gutterBottom>
              <Link to="/monitoring">{labels.monitoring.title}</Link>
            </Typography>
            <Typography variant="body1">
              Active {labels.monitoring.items}: {activeMentions?.length},{" "}
              {sortedSeverities.map(([severity, count], i) => (
                <span key={severity}>
                  {severity}: {count}
                  {i !== sortedSeverities.length - 1 ? ", " : ""}
                </span>
              ))}
            </Typography>
            <Typography variant="body1">
              {labels.monitoring.items} from the last 24 hours: {alertsFromLastDay()?.length}, Flagged: {flaggedAlerts.length}, Hidden
              {labels.monitoring.items}: {mentionsState.length - activeMentions.length}
            </Typography>
          </Box>
          {flaggedAlerts.length > 0 && (
            <Box p={1}>
              <Typography variant="h6" gutterBottom>
                <Link to="/flagging"> {labels.flagging.title} </Link>
              </Typography>
              <Typography variant="body1">
                Total {labels.flagging.items}: {flaggedAlerts.length}
              </Typography>
              <Typography variant="body1">
                {labels.flagging.items} collected manually: {linksNotCollectedManually?.length}
              </Typography>
            </Box>
          )}
          {flaggedAlerts.length > 0 && (
            <>
              <Box p={1}>
                <Typography variant="h6" gutterBottom>
                  <Link to="investigation">{labels.investigation.title}</Link>
                </Typography>
                <Typography variant="body1">
                  Total {labels.investigation.title}s: {flaggedAlerts.length}
                </Typography>
                <Typography variant="body1">
                  {labels.investigation.title}s in progress: {investigationsInProgress.length}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant="h6" gutterBottom>
                  <Link to="/action">{labels.takeAction.title}</Link>
                </Typography>
                <Typography variant="body1">
                  Total {labels.takeAction.items}: {flaggedAlerts.length}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Typography fontSize={8}>
          <a href="/push-data.json" target="_blank" rel="noopener noreferrer">
            Version data
          </a>
        </Typography>
      </Container>
    </div>
  );
}

export default Home;
