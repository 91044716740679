import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { MentionType } from "../state";
import AiFilterView from "./investigation/ai-filter/AiFilterView";
import GeneralView from "./investigation/GeneralView";
import TimeLine from "./investigation/TimeLine";
import Note from "./Note";
import SocialEmbed from "./SocialEmbed";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from "@mui/icons-material/Close";
import { Newspaper } from '@mui/icons-material';
import { SOCIAL_ICONS } from "../labels";



// a dialog to show the content of a mention

function MentionFocus({ mention, setMention }: { mention: MentionType | undefined; setMention: (mention: MentionType | undefined) => void }) {
    const handleClose = () => {
        setMention(undefined);
    };
    if (!mention) return null;
    const SelectedIcon = SOCIAL_ICONS[mention.source || ""] || Newspaper;

    return (
        <SwipeableDrawer
            variant="persistent"
            open={!!mention}
            onClose={handleClose}
            anchor={'right'}
            onOpen={console.log} >
            <Toolbar>
                <SelectedIcon />
                <Box sx={{ flexGrow: 0.01 }} />
                <Typography fontWeight={"bold"} >
                    {mention.source}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <Box sx={{ width: 600, padding: 2 }}>
                <GeneralView alert={mention} />
                <AiFilterView alert={mention} />
                <Note mention={mention} />
                <TimeLine alert={mention} />
                <SocialEmbed source={mention.source} url={mention.url} urn={mention.urn} />
            </Box>
        </SwipeableDrawer>
    );
}
export default MentionFocus;