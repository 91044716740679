import { Box, Container } from "@mui/material";
import { MentionType } from "../state";
import SocialEmbed from "./SocialEmbed";
import { GlobalSizes } from "../size";
import AiFilterView from "./investigation/ai-filter/AiFilterView";
import { SimilarAlerts } from "./SimilarAlerts";
import { useWsContext } from "../ws-context";
import Note from "./Note";

function RowContent({ mention }: { mention: MentionType }) {
  const { mentionsState } = useWsContext();
  return (
    <Box display="flex">
      {mention.source && mention.type !== "article" && <SocialEmbed source={mention.source} url={mention.url} urn={mention.urn} />}
      {mention.ai_filter && (
        <Box sx={{ maxWidth: GlobalSizes.cards.width, wordWrap: "break-word", marginLeft: GlobalSizes.gap }}>
          <AiFilterView alert={mention} />
        </Box>
      )}
      {mention.similar && mention.similar?.length > 0 && (
        <Container sx={{ height: GlobalSizes.cards.height, width: GlobalSizes.cards.width }}>
          <SimilarAlerts alert={mention} allMentions={mentionsState} />
        </Container>
      )}
      <Note mention={mention} />
    </Box>
  );
}

export default RowContent;
