import { MentionType } from "../../../state";
import { labels, stringToColor } from "../../../labels";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { Stack, Chip } from "@mui/material";
import RefreshAiFilter from "./RefreshAiFilter";
import { GlobalSizes } from "../../../size";

function AiFilterView({ alert }: { alert?: MentionType }) {
  if (!alert?.ai_filter) return null;

  const results = alert.ai_filter.results || [];
  const status = alert?.ai_filter?.status || labels.ai.success_count(results.length);

  return (
    <Box mt={GlobalSizes.gap}>
      <Stack direction="row" spacing={GlobalSizes.smallGap} alignItems="center">
        <Typography variant="h4">
          {labels.ai.title} <TroubleshootIcon />
        </Typography>
        <RefreshAiFilter alert={alert} />
      </Stack>
      <Typography variant="subtitle1">{status}</Typography>
      {results.map((result) => (
        <div key={result.key}>
          <p>
            <Chip
              label={result.key}
              sx={{
                backgroundColor: stringToColor(result.key),
                color: "black",
                fontSize: GlobalSizes.smallFontSize,
              }}
            />
            <br />
            {result.content?.explanation}
          </p>
        </div>
      ))}
    </Box>
  );
}

export default AiFilterView;
