import { ReactNode, useEffect, useState } from "react";
import { Typography, Container, Divider, Stack, Button, Grid } from "@mui/material";
import Loader from "../Components/Loader";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useRef } from "react";
import TweetCard from "../Components/investigation/tweet-screenshot/TweetCard";
import { HourglassTop, Email, Phone, School, Work, LocationOn } from "@mui/icons-material";
import { InvestigationSizes, GlobalSizes, pagesContainerMargins } from "../size";
import TimeLine from "../Components/investigation/TimeLine";
import TwitterData from "../Components/investigation/TwitterData";
import GeneralView from "../Components/investigation/GeneralView";
import AiFilterView from "../Components/investigation/ai-filter/AiFilterView";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Check from "@mui/icons-material/Check";
import { useLocation, useNavigate } from "react-router-dom";
import { SimilarAlerts } from "../Components/SimilarAlerts";
import ManualLinkForm from "../Components/investigation/ManualLinkForm";
import JSZip from "jszip";
import { useWsContext } from "../ws-context";
import ContactDetails from "../Components/investigation/ContactDetails";
import { useClientContext } from "../client-context";

const removeDuplicates = (array: string[]) => {
  return array.filter((item, index) => array.indexOf(item) === index);
}

function Investigation() {
  const [chosenInvestigation, setChosenInvestigation] = useState(new URLSearchParams(useLocation().search).get("url"));
  const [isInvestigationChanged, setIsInvestigationChanged] = useState(false);
  const { mentionsState, updateAlerts, emitNewData } = useWsContext();
  const { setCurrentAlert } = useClientContext();
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const cardRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  //Alert
  const alert = mentionsState.find((m) => m.url === chosenInvestigation);

  const flaggedAlerts = mentionsState.filter((mention) => mention.flagged);

  const twitterUser = alert?.twitter_data?.includes.users[0];

  const saveCustomTitle = async () => {
    console.log("saveCustomTitle", newTitle);
    if (!alert) return;

    const { url, customer_id } = alert;
    updateAlerts({ ...alert, title: newTitle });
    setIsTitleChanged(false);
    emitNewData({ action: "updateAlert", mention: { url, customer_id, title: newTitle } });
    setNewTitle("");
  };

  const downloadZip = async () => {
    const childAlerts = mentionsState.filter((m) => m.parent_mention === alert?.url);
    if (!childAlerts?.length) return;
    const zip = new JSZip();
    const images: { [key: string]: string[] } = {};
    for (const alert of childAlerts) {
      if (!alert.image || !alert.category) continue;
      const { category, image } = alert;
      images[category] = images[category] || [];
      images[category].push(image);
    }

    if (Object.keys(images).length === 0) return;

    try {
      for (const [category, imageUrls] of Object.entries(images)) {
        for (const imageUrl of imageUrls) {
          const imageName = imageUrl.split("/").pop();
          const imageBlob = await fetchImage(imageUrl);
          if (imageBlob) {
            zip.file(`${category}/${imageName}`, imageBlob, { binary: true });
          }
        }
      }

      const content = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = `images.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading zip file", error);
    }
  };


  const fetchImage = async (url: string) => {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Failed to fetch image from ${url}`);
    return await response.blob();
  };

  useEffect(() => {
    if (!chosenInvestigation && flaggedAlerts.length) {
      setChosenInvestigation(flaggedAlerts[0].url);
    }
    if (chosenInvestigation) {
      setCurrentAlert(chosenInvestigation);
    }
  }, [chosenInvestigation, flaggedAlerts, setCurrentAlert]);

  //!Build Drop down Element for posts
  const postsDropDown = (
    <Box py={GlobalSizes.gap}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Select post</InputLabel>
        <Select
          sx={{ maxWidth: InvestigationSizes.postsDropDownMaxWidth }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={chosenInvestigation}
          label="select post"
          onChange={(e) => {
            setChosenInvestigation(e.target.value);
          }}
        >
          {flaggedAlerts.map((currentAlert) => {
            if (alert?.twitter_data?.data?.author_id === currentAlert.twitter_data?.data?.author_id) {
              return (
                <MenuItem sx={{ maxWidth: InvestigationSizes.postsDropDownMaxWidth }} value={currentAlert.url} key={currentAlert.url}>
                  {currentAlert.description_short || currentAlert.url}
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      </FormControl>
    </Box>
  );

  //! Flags values for select element

  // Use an array to keep track of unique report values
  const uniqueFlagsValues: string[] = [];

  // Construct the array of MenuItem elements for the dropdown
  const flags = flaggedAlerts.map((alert, i): ReactNode => {
    const reportValue = alert.title || alert.user || alert.description_short || alert.url;
    const childAlerts = mentionsState.filter((m) => m.parent_mention === alert.url);
    // Check if the value is not in the array before adding it
    if (!uniqueFlagsValues.includes(reportValue)) {
      uniqueFlagsValues.push(reportValue);
      return (
        <MenuItem value={alert.url} key={alert.url}>
          <Stack direction="row" alignItems="center" gap={GlobalSizes.gap}>
            {childAlerts.length > 0 && <HourglassTop fontSize="small" />}
            <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{reportValue}</Typography>
          </Stack>
        </MenuItem>
      );
    } else {
      //Display none
      return (
        <MenuItem sx={{ display: "none" }} value={alert.url} key={alert.url}>
          {reportValue}
        </MenuItem>
      );
    }
  });

  //!JSX
  return (
    <div>
      <Bar label={labels.investigation.title + " | " + labels.investigation.subtitle} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          ...pagesContainerMargins,
        }}
      >
        {flaggedAlerts.length && chosenInvestigation ? (
          <>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Reported flag</InputLabel>
              <Select
                sx={{ maxWidth: InvestigationSizes.postsDropDownMaxWidth }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={chosenInvestigation}
                label="Reported flag"
                onChange={(e) => {
                  setIsInvestigationChanged(true);
                  setCurrentAlert(e.target.value);
                  setChosenInvestigation(e.target.value);
                  navigate(`/investigation?url=${encodeURIComponent(e.target.value)}`, { replace: true });
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxWidth: InvestigationSizes.postsDropDownMaxWidth,
                    },
                  },
                }}
              >
                {flags}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="standard">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  saveCustomTitle();
                }}
              >
                <Input
                  fullWidth
                  key={`${alert?.title}_${alert?.url}`}
                  sx={{ fontSize: GlobalSizes.title }}
                  defaultValue={alert?.title}
                  placeholder={alert?.user || alert?.url}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Save" color="primary" onClick={() => saveCustomTitle()}>
                        {isTitleChanged ? <Check /> : null}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(event) => {
                    setIsTitleChanged(!!event.target.value);
                    setNewTitle(event.target.value);
                  }}
                />
              </form>
            </FormControl>
            {mentionsState.filter((m) => alert?.url && m.parent_mention === alert?.url).length > 0 && (
              <Typography variant="h6" pb={1} color="primary" fontWeight="bold" sx={{ display: "flex", alignItems: "center" }}>
                <HourglassTop fontSize="small" />
                Investigation in progress...
              </Typography>
            )}
            {twitterUser ? (
              <>
                <TwitterData twitterUser={twitterUser} postsDropDown={postsDropDown} alert={alert} cardRef={cardRef} />
                <TweetCard alert={alert} ref={cardRef} />
              </>
            ) : (
              <GeneralView alert={alert} />
            )}
            {alert && (alert.last_time_70_enrich || alert.last_time_60_enrich) && (
              <Grid container spacing={GlobalSizes.mediumGap}>
                {<ContactDetails items={alert?.emails} Icon={Email} title="Email addresses" />}
                {<ContactDetails items={alert?.phone_numbers} Icon={Phone} title="Phone numbers" />}
                {alert?.education && (
                  <ContactDetails items={alert?.education?.map((edu) => edu?.display).filter(Boolean) as string[]} Icon={School} title="Education" />
                )}
                {<ContactDetails items={removeDuplicates(alert?.location_history || [] as string[])} Icon={LocationOn} title="Location history" />}
                {<ContactDetails items={alert?.work_history?.map((work) => work?.display).filter(Boolean) as string[]} Icon={Work} title="Work history" />}
              </Grid>
            )}
            <AiFilterView alert={alert} />
            <TimeLine alert={alert} />
            <SimilarAlerts alert={alert} allMentions={mentionsState} />
            <Divider sx={{ width: GlobalSizes.fullSize, mb: GlobalSizes.mediumGap }} />
            <Typography variant="h5" sx={{ my: GlobalSizes.mediumGap }}>
              Add Links
            </Typography>
            <ManualLinkForm currentAlert={alert} enableCategory={true} isInvestigationChanged={isInvestigationChanged} />
            <Box mt={GlobalSizes.gap}>
              <Button type="button" variant="contained" color="primary" onClick={downloadZip}>
                Download Images
              </Button>
            </Box>
          </>
        ) : (
          flaggedAlerts.length === 0 && (
            <Typography color="primary" variant="h6">
              No investigations found, start by flagging an alert in the monitoring section...
            </Typography>
          )
        )}
        <Loader />
      </Container>
    </div>
  );
}

export default Investigation;
