import { AiFilterType, AiResultType, MentionType } from "../state";
import {
  Stack,
  Container,
  Button,
  CircularProgress,
  Typography,
  Avatar,
  Chip,
  Badge,
  Box,
  ButtonGroup,
  Tooltip,
  SelectChangeEvent,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Bar from "../Components/Bar";
import { labels, severity, SOCIAL_ICONS, stringToColor } from "../labels";
import { useCallback, useEffect, useState, useMemo } from "react";
import { GlobalSizes, pagesContainerMargins } from "../size";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridInitialState,
  GridListColDef,
} from "@mui/x-data-grid-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import List from "@mui/icons-material/List";
import GridOnIcon from "@mui/icons-material/GridOn";
import PersonIcon from "@mui/icons-material/Person";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomTableViews from "../Components/monitoring/CustomTableViews";
import { getIdenticalAlert, getSimilarSeverity } from "../Components/SimilarAlerts";
import FlagIcon from "@mui/icons-material/Flag";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SeverityChip from "../Components/SeverityChip";
import RefreshAiFilter from "../Components/investigation/ai-filter/RefreshAiFilter";
import LinearProgress from "@mui/material/LinearProgress";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { getViews, setViews } from "../team-util";
import { useWsContext } from "../ws-context";
import RowContent from "../Components/RowContent";
import { useClientContext } from "../client-context";
import MentionFocus from "../Components/MentionFocus";

const initialGridState: GridInitialState = {
  columns: {
    columnVisibilityModel: {
      refresh_ai_filter: false,
      ai_filter_beta: false,
      takedown_status: false,
    },
  },
  filter: {
    filterModel: {
      items: [{ field: "status", operator: "not", value: "hidden" }],
    },
  },
  sorting: {
    sortModel: [{ field: "detection_date", sort: "desc" }],
  },
  pagination: {
    paginationModel: { pageSize: 1000, page: 0 },
  },
  pinnedColumns: { right: ["status"] },
  density: "standard",
};

function LinearLoadingOverlay() {
  const { dashboardState, isLoadingMentions } = useWsContext();
  return <div style={{ width: "100%", height: "5px" }}>{!dashboardState.customer_id || isLoadingMentions ? <LinearProgress /> : null}</div>;
}

function Monitoring() {
  const { mentionsState, dashboardState, emitNewData, updateAlerts, socketRef, isLoadingMentions } = useWsContext();
  const [focusedMention, setFocusedMention] = useState<MentionType>();
  const { tempView, setTempView } = useClientContext();
  const nav = useNavigate();
  const [isUploadingCsv, setIsUploadingCsv] = useState({
    name: "",
    status: "",
  });
  const [tableStateChanged, setTableStateChanged] = useState(false);
  const [currentView, setCurrentView] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [displayedAlerts, setDisplayedAlerts] = useState(0);
  const [isListView, setIsListView] = useState(false);

  const apiRef = useGridApiRef();

  const sources = useMemo(
    () =>
      Array.from(new Set(mentionsState.map((m) => m.source).filter(Boolean)))
        .sort()
        .map((source) => ({ value: source, label: source })),
    [mentionsState]
  );

  const autosizeColumns = useCallback(() => {
    if (!apiRef.current?.autosizeColumns) return;
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    });
  }, [apiRef]);

  const handleUploadCsv = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !file.type.endsWith("csv")) {
      console.error("No appropriate file selected");
      event.target.value = "";
      return;
    }

    setIsUploadingCsv({ name: file.name, status: "loading" });
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csv = e.target?.result;
      if (!csv) return;
      emitNewData({ action: "uploadCsv", csv });
      const handleWebSocketMessage = (event: MessageEvent) => {
        const { data } = JSON.parse(event.data);
        if (data?.csv === "completed") {
          setIsUploadingCsv({ name: file.name, status: "completed" });
        }
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      };
      event.target.value = "";
      socketRef.current?.addEventListener("message", handleWebSocketMessage);
    };
    reader.readAsText(file);
  };

  const updateAlertRequest = useCallback(
    async (mentionToUpdate: MentionType) => {
      const mention = {
        ...mentionToUpdate,
        customer_id: dashboardState.customer_id,
      };

      emitNewData({ action: "updateAlert", mention });
    },
    [dashboardState.customer_id, emitNewData]
  );

  const updateWhitelisted = useCallback(
    (mentionToUpdate: MentionType) => {
      if (mentionToUpdate.whitelisted) {
        const { alertType, ...rest } = mentionToUpdate;
        updateAlerts({ ...rest, severity: "low" });
        updateAlertRequest({ url: mentionToUpdate.url, severity: "low", whitelisted: true });
        return;
      }
      updateAlerts(mentionToUpdate);
      updateAlertRequest({ url: mentionToUpdate.url, whitelisted: false });
    },
    [updateAlertRequest, updateAlerts]
  );

  const handleViewChange = (event: SelectChangeEvent<string>) => {
    if (!dashboardState.customer_id) return;
    setCurrentView(event.target.value);
    const allViews = getViews();
    const chosenView = dashboardState.saved_views?.[event.target.value];
    if (!chosenView) return;
    apiRef.current.restoreState(chosenView);
    setViews({ ...allViews, [dashboardState.customer_id]: event.target.value });
    setTempView({});
  };

  const aiFilterValueGetter = useCallback(
    (cell: AiFilterType, row: MentionType, ai_filter_key: keyof MentionType) => {
      const identicalAlert = getIdenticalAlert(row, mentionsState);
      let aiFilter = identicalAlert?.[ai_filter_key] as AiFilterType;

      const aiFilterData = identicalAlert && aiFilter?.status !== "failed" ? aiFilter : cell;
      if (!aiFilterData) return null;
      if (!aiFilterData.results) return aiFilterData.status;
      const trueAnswers = aiFilterData.results?.filter((q: AiResultType) => {
        return q.content?.booleanAnswer;
      });
      return trueAnswers?.length.toString().padStart(5, "0") + " " + trueAnswers?.map((q) => q.key?.replaceAll("_", " ")).join(" ");
    },
    [mentionsState]
  );

  const aiFilterRenderCell = useCallback(
    (aiFilter: AiFilterType, params: GridRenderCellParams, isBeta: boolean) => {
      let fromSimilarFilter = false;
      if (!isBeta && (params.row?.similar?.[0]?.score || 0) > 0.9) {
        const similarMention = mentionsState.find((m) => m.url === params.row.similar?.[0].url);
        if (similarMention?.ai_filter && similarMention?.ai_filter.status !== "failed") {
          fromSimilarFilter = true;
          aiFilter = similarMention?.ai_filter;
        }
      }
      if (!aiFilter) return null;
      if (!aiFilter.results) {
        return (
          <Tooltip title={aiFilter.reason}>
            <Chip size="small" label={aiFilter.status} />
          </Tooltip>
        );
      }

      const tableTrueQuestions = aiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.content?.explanation} key={index}>
          <Chip size="small" label={value.key} sx={{ backgroundColor: stringToColor(value.key), color: "black", marginLeft: GlobalSizes.smallGap }} />
        </Tooltip>
      ));

      const listTrueQuestions = aiFilter.results?.map((value: AiResultType, index: number) => (
        <Tooltip title={value.key} key={index}>
          <Box
            sx={{
              width: 25,
              height: 5,
              borderRadius: "25%",
              backgroundColor: stringToColor(value.key),
              display: "inline-block",
              marginLeft: GlobalSizes.smallGap,
            }}
          />
        </Tooltip>
      ));

      return (
        <div>
          <Tooltip key={params.row.url} title={labels.ai.success_count(tableTrueQuestions.length) + (fromSimilarFilter ? " (from similar alert)" : "")}>
            <Chip
              size="small"
              icon={fromSimilarFilter ? <FindReplaceIcon /> : <TroubleshootIcon />}
              label={tableTrueQuestions.length}
              sx={{ marginRight: GlobalSizes.smallGap }}
            />
          </Tooltip>
          {isListView ? listTrueQuestions : tableTrueQuestions}
        </div>
      );
    },
    [mentionsState, isListView]
  );

  const StatusButtons = useCallback(
    (alert: MentionType) => {
      return (
        <ButtonGroup sx={{ mt: GlobalSizes.smallGap }} color="inherit" size="small" variant="contained" aria-label="Basic button group">
          <Tooltip title={alert.hidden ? "Unhide" : "Hide"} placement="top">
            <Button
              color="inherit"
              onClick={() => {
                updateAlertRequest({ url: alert.url, hidden: !alert.hidden });
                updateAlerts({ ...alert, hidden: !alert.hidden });
              }}
              aria-label="Hide Alert"
            >
              {alert.hidden ? <VisibilityIcon /> : <VisibilityOffIcon color="disabled" />}
            </Button>
          </Tooltip>
          <Tooltip title={alert.flagged ? "See flag" : "Flag"} placement="top">
            <Button
              color={alert.flagged ? "error" : "inherit"}
              onClick={() => {
                if (alert.flagged) {
                  nav(`/flagging?url=${encodeURIComponent(alert.url)}`);
                } else {
                  updateAlerts({ ...alert, flagged: true, flag_date: Date.now() });
                  updateAlertRequest({ url: alert.url, flagged: true, flag_date: Date.now() });
                }
              }}
              aria-label="Flag Alert"
            >
              <FlagIcon color={!alert.flagged ? "disabled" : "action"} />
            </Button>
          </Tooltip>
          <Tooltip title={alert.whitelisted ? "Remove from whitelist" : "Add to whitelist"} placement="top">
            <Button
              color={alert.whitelisted ? "success" : "inherit"}
              onClick={() => {
                updateWhitelisted({ ...alert, whitelisted: !alert.whitelisted });
              }}
              aria-label="Whitelist Alert"
            >
              <VerifiedUserIcon color={!alert.whitelisted ? "disabled" : "action"} />
            </Button>
          </Tooltip>
        </ButtonGroup>
      );
    },
    [nav, updateAlertRequest, updateAlerts, updateWhitelisted]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "avatar",
        headerName: "👤",
        width: 60,
        renderCell: (params: GridRenderCellParams) => {
          const SelectedIcon = SOCIAL_ICONS[params.row.source] || PersonIcon;
          return (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
            >
              <Avatar alt={params.row.user} src={params.value} />
            </Badge>
          );
        },
      },
      {
        field: "user",
        headerName: "User",
        width: 150,
        type: "string" as const,
      },
      {
        field: "source",
        headerName: "Source",
        width: 120,
        type: "singleSelect" as const,
        valueOptions: sources,
      },
      {
        field: "detection_date",
        headerName: "Detected",
        type: "date" as const,
        valueFormatter: (value: Date) => {
          const date = new Date(value);
          return date.toLocaleDateString(undefined, { month: "short", day: "numeric" });
        },
      },
      {
        field: "creation_date",
        headerName: "Created",
        minWidth: 90,
        type: "date" as const,
        valueGetter: (value: Date) => {
          return value ? new Date(value) : 0;
        },
      },
      {
        field: "description_short",
        headerName: "Content",
        width: 300,
        maxWidth: 600,
        type: "string" as const,
      },
      {
        field: "url",
        headerName: "Link",
        width: 300,
        maxWidth: 600,
        type: "string" as const,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link to={params.row.url} target="_blank" rel="noopener noreferrer">
              {params.row.url}
            </Link>
          );
        },
      },
      {
        field: "impressions",
        headerName: "Impressions",
        type: "number" as const,
      },
      {
        field: "alertType",
        headerName: "Risk",
        type: "string" as const,
      },
      {
        field: "type",
        headerName: "Item",
        type: "string" as const,
      },
      {
        field: "severity",
        headerName: "Severity",
        type: "singleSelect" as const,
        editable: true,
        valueGetter: (value: string, params: MentionType) => {
          const finalValue = params?.manual_severity || getSimilarSeverity(params, mentionsState) || value || "medium";
          return severity[finalValue.toUpperCase()].value;
        },
        valueOptions: [
          { value: 4, label: "Critical" },
          { value: 3, label: "High" },
          { value: 2, label: "Medium" },
          { value: 1, label: "Low" },
        ],
        renderCell: (params: GridRenderCellParams) => {
          return <SeverityChip alert={params.row} />;
        },
      },
      {
        field: "similar",
        headerName: "Similarity",
        width: 120,
        type: "number" as const,
        valueGetter: (value: { score: number }[]) => {
          return value?.[0]?.score ? value[0].score * 100 : null;
        },
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) return null;
          return (
            <div>
              <Tooltip key={params.row.url} title="Expand row to see similar alerts">
                <Chip label={params.value.toFixed(2) + "%"} variant="outlined" />
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "asset",
        headerName: "Asset",
        type: "string" as const,
      },
      {
        field: "location",
        headerName: "Location",
        type: "string" as const,
      },
      {
        field: "takedown_status",
        headerName: "Takedown Status",
        type: "string" as const,
      },
      {
        field: "ai_filter",
        headerName: labels.ai.title,
        type: "string" as const,
        valueGetter: (cell: AiFilterType, row: MentionType) => {
          return aiFilterValueGetter(cell, row, "ai_filter");
        },
        renderCell: (params: GridRenderCellParams) => {
          return aiFilterRenderCell(params.row.ai_filter, params, false);
        },
      },
      {
        field: "ai_filter_beta",
        headerName: labels.ai.title_beta,
        valueGetter: (cell: AiFilterType, row: MentionType) => {
          return aiFilterValueGetter(cell, row, "ai_filter_beta");
        },
        renderCell: (params: GridRenderCellParams) => {
          return aiFilterRenderCell(params.row.ai_filter_beta, params, true);
        },
      },
      {
        field: "refresh_ai_filter",
        headerName: `Refresh ${labels.ai.title}`,
        renderCell: (params: GridRenderCellParams) => {
          return <RefreshAiFilter alert={params.row} />;
        },
      },
      {
        field: "status",
        headerName: "Status",
        type: "singleSelect" as const,
        valueGetter: (value: undefined, row: MentionType) => {
          if (row.hidden) return "hidden";
          if (row.flagged) return "flagged";
          if (row.whitelisted) return "whitelisted";
        },
        valueOptions: [
          { value: "hidden", label: "hidden" },
          { value: "flagged", label: "flagged" },
          { value: "whitelisted", label: "whitelisted" },
        ],
        width: 155,
        renderCell: (params: GridRenderCellParams) => {
          return dashboardState && StatusButtons(params.row);
        },
      },
    ],
    [mentionsState, aiFilterRenderCell, aiFilterValueGetter, sources, StatusButtons, dashboardState]
  );

  const listViewColumns = useMemo(() => columns.filter((col) => col.field !== "status"), [columns]);

  const ListViewCell = useCallback(
    (params: GridRenderCellParams) => {
      const creationDate = new Date(params.row.creation_date);
      const SelectedIcon = SOCIAL_ICONS[params.row.source] || PersonIcon;
      return (
        <Stack
          onClick={() => {
            setFocusedMention(params.row);
          }}
          direction="row"
          spacing={GlobalSizes.gap}
          alignItems="flex-start"
          sx={{
            padding: GlobalSizes.gap,
            width: GlobalSizes.fullSize,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
            height: GlobalSizes.fullSize,
            overflowY: "auto",
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
          >
            <Avatar sx={{ width: 54, height: 54 }} alt={params.row.user} src={params.row.avatar} />
          </Badge>
          <Stack flexGrow={GlobalSizes.smallGap} gap={GlobalSizes.smallGap}>
            <Typography variant="subtitle2" fontWeight="bold" color="text.primary" component="span">
              {params.row.user}
              {params.row.source && (
                <Typography component="span" variant="caption" color="text.secondary">
                  {" • "}
                  {params.row.source}
                </Typography>
              )}
              {params.row.creation_date && (
                <Typography component="span" variant="caption" color="text.secondary">
                  {params.row.source && " • "}
                  {creationDate.toLocaleDateString(undefined, { month: "short", day: "numeric" })}
                </Typography>
              )}
              {params.row.severity && (
                <Typography component="span" variant="caption" color="text.secondary">
                  {" • "}
                  <SeverityChip alert={params.row} />
                </Typography>
              )}
              {params.row.ai_filter && <Box my={1}>{aiFilterRenderCell(params.row.ai_filter, params, false)}</Box>}
            </Typography>
            {params.row.description_short && (
              <Typography variant={params.row.type !== "article" ? "body2" : "body1"} color="text.primary" sx={{ mb: GlobalSizes.smallGap }}>
                {params.row.description_short}
              </Typography>
            )}
            {params.row.full_content && (
              <Typography variant="body2" color="text.secondary">
                {params.row.full_content}...
              </Typography>
            )}
          </Stack>
          {StatusButtons(params.row)}
        </Stack>
      );
    },
    [StatusButtons, aiFilterRenderCell]
  );

  const listColDef: GridListColDef = useMemo(() => {
    return {
      field: "listColumn",
      renderCell: ListViewCell,
    };
  }, [ListViewCell]);

  const restoreStateToDefault = useCallback(() => {
    apiRef.current.restoreState(initialGridState);
  }, [apiRef]);

  const handleViewUpdate = useCallback(() => {
    if (isLoadingMentions) return;
    setTempView(apiRef.current.exportState());
    if (!Object.keys(dashboardState.saved_views || {}).length) return;
    setTableStateChanged(true);
  }, [apiRef, dashboardState.saved_views, isLoadingMentions, setTempView]);

  useEffect(() => {
    const restoreViewState = (view?: GridInitialState) => {
      if (tempView.columns && !isLoadingMentions && !isListView) {
        apiRef.current.restoreState(tempView);
      } else if (view) {
        apiRef.current.restoreState(view);
      } else {
        restoreStateToDefault();
      }
    };

    const updateCurrentView = () => {
      const savedView = getViews()[dashboardState.customer_id!];
      const savedViews = dashboardState.saved_views || {};
      const firstSavedView = Object.keys(savedViews)[0];

      if (savedView) {
        setCurrentView(savedView);
        restoreViewState(savedViews[savedView]);
      } else if (firstSavedView) {
        setCurrentView(firstSavedView);
        restoreViewState(savedViews[firstSavedView]);
      } else {
        setCurrentView(labels.monitoring.DEFAULT_VIEW);
        restoreViewState();
      }
    };

    if (!tableStateChanged && dashboardState.customer_id) {
      updateCurrentView();
    }

    setTableStateChanged(false);
  }, [isListView, isLoadingMentions, apiRef, dashboardState.customer_id, tableStateChanged, dashboardState.saved_views, restoreStateToDefault, tempView]);

  useEffect(() => {
    if (!dashboardState.customer_id) return;
    const savedView = getViews()[dashboardState.customer_id];
    if (!savedView && dashboardState.customer_id) {
      setTimeout(() => {
        autosizeColumns();
      }, 100);
    }
  }, [dashboardState.customer_id, autosizeColumns]);

  useEffect(() => {
    if (tempView.columns) {
      apiRef.current.restoreState(tempView);
      setTableStateChanged(true);
    }
  }, [mentionsState, tempView, apiRef]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ToggleButtonGroup
          size="small"
          value={isListView}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, isListView: boolean) => {
            console.log({ event, isListView });
            if (!isListView) {
              if (dashboardState.saved_views?.[currentView]) {
                apiRef.current.restoreState(dashboardState.saved_views[currentView]);
              }
            }
            setIsListView(isListView);
          }}
          aria-label="view type"
        >
          <Tooltip title="Table View">
            <ToggleButton value={false} aria-label="Table View">
              <GridOnIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="List View">
            <ToggleButton value={true} aria-label="List View">
              <List />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>

        <Tooltip title="Customize filter and column views" placement="top">
          <Button startIcon={<ViewCompactIcon />} onClick={() => setOpenDialog(true)}>
            {currentView && currentView !== labels.monitoring.DEFAULT_VIEW
              ? `${currentView}${tableStateChanged ? " *" : ""}`
              : !Object.keys(dashboardState.saved_views || {}).length && labels.monitoring.DEFAULT_VIEW}
          </Button>
        </Tooltip>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Box sx={{ flexGrow: GlobalSizes.smallGap }} />
        <GridToolbarExport />
        <Tooltip
          title={
            {
              loading: `Uploading ${isUploadingCsv.name}...`,
              completed: `${isUploadingCsv.name} uploaded successfully!`,
              error: `Failed to upload ${isUploadingCsv.name}, please try again!`,
              processing: labels.monitoring.csv,
            }[isUploadingCsv.status] || "Upload alerts via CSV"
          }
        >
          <Button
            size="small"
            component="label"
            startIcon={
              <Badge
                badgeContent={
                  {
                    completed: <DoneOutlinedIcon fontSize="inherit" />,
                    error: <CloseOutlinedIcon fontSize="inherit" />,
                  }[isUploadingCsv.status] || null
                }
              >
                {{ loading: <CircularProgress size={GlobalSizes.smallFontSize} />, processing: <CircularProgress size={GlobalSizes.smallFontSize} /> }[
                  isUploadingCsv.status
                ] || <FileUploadOutlinedIcon fontSize="small" />}
              </Badge>
            }
          >
            <input type="file" hidden accept=".csv" onChange={handleUploadCsv} />
            Import
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  //!JSX
  return (
    <div>
      <Bar label={labels.monitoring.title + " | " + labels.monitoring.subtitle} />
      <Container sx={{ height: "calc(100vh - 180px)", minWidth: GlobalSizes.fullSize, ...pagesContainerMargins }}>
        <p>Reporting and anomaly detection with continuous monitoring.</p>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">{`Showing ${displayedAlerts} of ${mentionsState.length} Total Alerts`}</Typography>
        </Stack>
        {openDialog && (
          <CustomTableViews
            openDialog={openDialog}
            currentView={currentView}
            setCurrentView={setCurrentView}
            gridState={apiRef.current.exportState()}
            handleCloseDialog={() => {
              setOpenDialog(false);
              setTableStateChanged(false);
            }}
            handleViewChange={handleViewChange}
            tableStateChanged={tableStateChanged}
            restoreStateToDefault={restoreStateToDefault}
          />
        )}
        <LinearLoadingOverlay />
        <DataGridPro
          headerFilters
          onColumnVisibilityModelChange={handleViewUpdate}
          onDensityChange={handleViewUpdate}
          onFilterModelChange={handleViewUpdate}
          onSortModelChange={handleViewUpdate}
          onPaginationModelChange={handleViewUpdate}
          onColumnWidthChange={handleViewUpdate}
          onColumnOrderChange={handleViewUpdate}
          onPinnedColumnsChange={handleViewUpdate}
          onStateChange={() =>
            setDisplayedAlerts(mentionsState.length > 0 && apiRef.current.exportState() ? apiRef.current.exportState().pagination?.rowCount ?? 0 : 0)
          }
          slots={{ toolbar: CustomToolbar }}
          rows={mentionsState}
          rowHeight={isListView ? 150 : undefined}
          columns={isListView ? listViewColumns : columns}
          unstable_listView={isListView}
          unstable_listColumn={listColDef}
          apiRef={apiRef}
          getRowId={(row) => row.url}
          pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
          pagination
          processRowUpdate={async (newRow, oldRow) => {
            const sevKey = Object.keys(severity).find((key) => severity[key].value === newRow.severity);
            newRow.manual_severity = sevKey?.toLowerCase();
            newRow.severity = oldRow.severity;
            updateAlerts(newRow);
            await updateAlertRequest({ manual_severity: newRow.manual_severity, severity: newRow.severity, url: newRow.url });
            return newRow;
          }}
          initialState={initialGridState}
          getDetailPanelHeight={() => 450}
          getDetailPanelContent={({ row }) => <RowContent mention={row} />}
        />
      </Container>
      <MentionFocus mention={focusedMention} setMention={setFocusedMention} />
    </div>
  );
}

export default Monitoring;
