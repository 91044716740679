import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GlobalSizes } from "../../size";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { getViews, setViews } from "../../team-util";
import { labels } from "../../labels";
import { useWsContext } from "../../ws-context";
import { useClientContext } from "../../client-context";

interface CustomTableViewsProps {
  openDialog: boolean;
  currentView: string;
  setCurrentView: React.Dispatch<React.SetStateAction<string>>;
  gridState: GridInitialStatePro;
  handleCloseDialog: () => void;
  handleViewChange: (event: SelectChangeEvent<string>) => void;
  tableStateChanged: boolean;
  restoreStateToDefault: () => void;
}

function CustomTableViews({
  tableStateChanged,
  openDialog,
  currentView,
  setCurrentView,
  gridState,
  handleCloseDialog,
  handleViewChange,
  restoreStateToDefault,
}: CustomTableViewsProps) {
  const [newViewName, setNewViewName] = useState("");
  const [newViewError, setNewViewError] = useState(false);

  const { updateDashboard, emitNewData, dashboardState } = useWsContext();
  const { setTempView } = useClientContext();

  const handleNewViewNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewViewName(e.target.value);
    setNewViewError(!/^[a-zA-Z0-9_ ]+$/.test(e.target.value) || !e.target.value);
  };

  const addNewView = async () => {
    if (!dashboardState.customer_id) return;
    const normalizedViewName = newViewName.replace(/ /g, "_");
    const { pagination, preferencePanel, ...tableStateAttributes } = gridState;

    console.log({ normalizedViewName });

    updateDashboard({
      saved_views: {
        ...dashboardState.saved_views,
        [normalizedViewName]: tableStateAttributes,
      },
    });

    setCurrentView(normalizedViewName);
    handleCloseDialog();

    emitNewData({ action: "setAlertTable", viewId: normalizedViewName, view: tableStateAttributes });
    setViews({ ...getViews(), [dashboardState.customer_id]: normalizedViewName });
    setTempView({});
  };

  const saveTableSettings = async () => {
    if (!currentView) return;
    const { pagination, preferencePanel, ...tableStateAttributes } = gridState;

    updateDashboard({
      saved_views: {
        ...dashboardState.saved_views,
        [currentView]: tableStateAttributes,
      },
    });

    handleCloseDialog();
    emitNewData({ action: "setAlertTable", viewId: currentView, view: tableStateAttributes });

    setTempView({});
  };

  const removeView = async () => {
    if (!dashboardState.customer_id) return;
    const { [currentView]: removedView, ...restViews } = dashboardState.saved_views || {};

    if (!currentView) return;

    updateDashboard({
      saved_views: restViews,
    });

    if (!Object.keys(restViews).length) {
      setCurrentView(labels.monitoring.DEFAULT_VIEW);
      restoreStateToDefault();
    } else {
      setCurrentView(Object.keys(restViews)[0]);
    }

    handleCloseDialog();

    emitNewData({ action: "removeView", viewId: currentView });
    const updatedViews = getViews();
    delete updatedViews[dashboardState.customer_id];
    setViews(updatedViews);
    setTempView({});
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          width: GlobalSizes.smallSize,
        },
      }}
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Typography mb={GlobalSizes.smallGap} variant="h5">
          Choose table view
        </Typography>
        <Stack direction="row" width={GlobalSizes.midSize} alignItems="center" mb={GlobalSizes.gap}>
          <FormControl sx={{ width: GlobalSizes.fullSize }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={currentView || labels.monitoring.DEFAULT_VIEW}
              sx={{ borderRadius: GlobalSizes.none }}
              onChange={(e) => {
                handleViewChange(e);
                handleCloseDialog();
              }}
            >
              {!Object.keys(dashboardState.saved_views || {}).length && <MenuItem value={labels.monitoring.DEFAULT_VIEW}>Default</MenuItem>}
              {Object.keys(dashboardState.saved_views || {}).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {tableStateChanged && currentView && currentView !== labels.monitoring.DEFAULT_VIEW && (
            <Button
              color="secondary"
              sx={{ height: 40, width: GlobalSizes.midSize, borderRadius: GlobalSizes.none }}
              variant="contained"
              size="small"
              onClick={() => saveTableSettings()}
            >
              Save changes
            </Button>
          )}
        </Stack>
        <Typography variant="h5">Create new view</Typography>
        <TextField
          sx={{ width: GlobalSizes.midSize }}
          id="save-icon"
          error={newViewError}
          helperText={newViewError ? "Only letters, numbers, spaces and underscores are allowed" : ""}
          label="View Name"
          required
          value={newViewName}
          onChange={handleNewViewNameChange}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ mb: GlobalSizes.gap }}>
                <IconButton disabled={newViewError} aria-label="toggle icon visibility" edge="end" size="small" onClick={() => addNewView()}>
                  <CheckIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        {currentView && currentView !== labels.monitoring.DEFAULT_VIEW && (
          <Button color="secondary" onClick={() => removeView()}>
            Delete View
          </Button>
        )}
        <Button onClick={() => handleCloseDialog()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomTableViews;
