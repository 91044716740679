import { ReactNode, useEffect, useState } from "react";
import { MentionType } from "../state";
import {
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
  Container,
  CardContent,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Box,
  Tooltip,
} from "@mui/material";
import Loader from "../Components/Loader";
import Bar from "../Components/Bar";
import { labels, unixToLocaleDate } from "../labels";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import FlagIcon from "@mui/icons-material/Flag";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { GlobalSizes, FlaggingSizes, pagesContainerMargins } from "../size";
import LinkButton from "../Components/LinkButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";

function Flagging() {
  const { dashboardState, emitNewData, mentionsState, updateAlerts } = useWsContext();
  const { setCurrentAlert } = useClientContext();

  const [flagInput, setFlagInput] = useState("");

  const [expanded, setExpanded] = useState<string | false>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const flaggedAlerts = mentionsState.filter((mention) => mention.flagged);

  const extendAccordion = (panel: string) => (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
    if (isExpanded) {
      navigate(`/flagging?url=${encodeURIComponent(panel)}`, { replace: true });
      setCurrentAlert(panel);
      setExpanded(panel);
    } else {
      navigate(`/flagging`, { replace: true });
      setCurrentAlert("");
      setExpanded(false);
    }
  };

  async function handleFlagClick() {
    setFlagInput("");
    try {
      const mention = {
        url: flagInput,
        flagged: true,
        collected_manually: true,
        detection_date: Date.now(),
        flag_date: Date.now(),
        customer_id: dashboardState.customer_id,
      };
      updateAlerts(mention);
      emitNewData({ action: "updateAlert", mention });
    } catch (error) {
      console.error("Error publishing flag: ", error);
    }
  }

  const sortedFlaggedAlerts = flaggedAlerts.sort((a, b) => b.flag_date || 0 - (a.flag_date || 0));

  useEffect(() => {
    if (!!flagInput) return;
    const clickedFlag = new URLSearchParams(location.search).get("url");
    if (!flaggedAlerts?.length || !clickedFlag) return;
    setCurrentAlert(clickedFlag);
    setExpanded(clickedFlag);
    const element = document.getElementById(clickedFlag);
    element?.scrollIntoView({ behavior: "smooth" });
  }, [location.search, flaggedAlerts, setCurrentAlert, flagInput]);

  const links = sortedFlaggedAlerts?.map((alert: MentionType, i): ReactNode => {
    return (
      <Accordion
        sx={{
          mt: GlobalSizes.gap,
        }}
        key={alert.url}
        expanded={expanded === alert.url}
        onChange={extendAccordion(alert.url)}
      >
        <AccordionSummary expandIcon={expanded ? <ExpandLess /> : <ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Box sx={{ display: "flex", width: GlobalSizes.fullSize, flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: GlobalSizes.gap, width: GlobalSizes.fullSize }}>
              <Typography variant="body1" component="div" sx={{ wordBreak: "break-word" }}>
                {i + 1}. {alert.title || alert.user || alert.url}
              </Typography>
              <Avatar alt="Avatar" src={alert?.avatar} />
              {alert.flag_date && (
                <Tooltip title={`Flagged at: ${unixToLocaleDate(alert.flag_date)}`} placement="top">
                  <Typography variant="caption" sx={{ mt: FlaggingSizes.mtCaption, marginLeft: "auto" }}>
                    {new Date(alert.flag_date).toLocaleDateString()}
                  </Typography>
                </Tooltip>
              )}
            </Box>
            <Typography
              variant="caption"
              component="a"
              href={alert.url}
              target="_blank"
              sx={{ mt: FlaggingSizes.mtCaption, width: GlobalSizes.fullSize, wordBreak: "break-word" }}
            >
              {alert.url}
            </Typography>
            <Typography variant="caption" sx={{ mt: FlaggingSizes.mtCaption }}>
              {alert.title || alert.user || alert.source}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Card key={i} id={alert.url}>
            <CardActionArea href={alert.url} rel="noreferrer" target="_blank">
              <CardContent>
                <CardHeader sx={{ pl: GlobalSizes.none }} avatar={<Avatar alt={alert.user} src={alert?.avatar} />} title={alert.user} />
                <Typography variant="body2" className="report_description" color="text.secondary">
                  {alert.description_short}
                </Typography>
                <Typography variant="body2" className="report_description" color="text.secondary">
                  {alert.url}
                </Typography>
                {alert.collected_manually && (
                  <Typography variant="body2" color="error" sx={{ mt: FlaggingSizes.mtCaption }}>
                    Manually Flagged
                  </Typography>
                )}
              </CardContent>
            </CardActionArea>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LinkButton destination={`/investigation?url=${encodeURIComponent(alert.url)}`} label={labels.investigation.title} />
            </Box>
          </Card>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div>
      <Bar label={labels.flagging.title + " | " + labels.flagging.subtitle} />
      <Container sx={{ ...pagesContainerMargins }}>
        <p>Discovered and reported suspicious links:</p>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: FlaggingSizes.paperWidth,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleFlagClick();
          }}
        >
          <FlagIcon />
          <InputBase
            sx={{ flex: GlobalSizes.flex }}
            placeholder="Report a suspicious link"
            inputProps={{ "aria-label": "Report a suspicious link" }}
            onChange={(e) => setFlagInput(e.target.value)}
            value={flagInput}
          />
          <IconButton type="submit" sx={{ p: GlobalSizes.buttonPadding }} aria-label="flag" disabled={!flagInput}>
            Flag
          </IconButton>
        </Paper>
        <Box mt={GlobalSizes.gap}>{links}</Box>
        <Loader />
      </Container>
    </div>
  );
}

export default Flagging;
