import { MentionType } from "../state";
import { Chip, Tooltip } from "@mui/material";
import { severity } from "../labels";
import CheckIcon from "@mui/icons-material/Check";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { getSimilarSeverity } from "./SimilarAlerts";
import { useWsContext } from "../ws-context";

function SeverityChip({ alert }: { alert: MentionType }) {
  const { mentionsState } = useWsContext();
  let icon;
  let displaySeverity = alert.severity || "medium";
  let tooltip = "Default severity based on alert type";
  if (alert.manual_severity) {
    icon = <CheckIcon />;
    displaySeverity = alert.manual_severity;
    tooltip = "Severity manually set by user to train the system";
  } else {
    const similarSeverity = getSimilarSeverity(alert, mentionsState);
    if (similarSeverity) {
      icon = <FindReplaceIcon />;
      displaySeverity = similarSeverity;
      tooltip = "AI generated severity using similarity to other alerts";
    }
  }
  displaySeverity = displaySeverity.toUpperCase();
  return (
    <Tooltip title={`${displaySeverity} - ${tooltip}`}>
      <Chip icon={icon} size="small" label={displaySeverity} sx={{ backgroundColor: severity[displaySeverity]?.color, color: "black" }} />
    </Tooltip>
  );
}

export default SeverityChip;
