import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { FaHeartbeat, FaFlag, FaGavel } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { labels } from "../labels";
import { Home, ScatterPlot } from "@mui/icons-material";
import { Avatar, Container, Divider, Stack, Typography } from "@mui/material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { GlobalSizes, SidePanelSizes, avatarSize} from "../size";
import SecurityIcon from "@mui/icons-material/Security";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import Groups2Icon from "@mui/icons-material/Groups2";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";

function SidePanel(props: { user?: User }) {
  const { logout } = useAuth0();
  const { dashboardState } = useWsContext();
  const { currentAlert } = useClientContext();

  const userTextEmail = props.user!.email;

  const logoutUser = () => {
    localStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getNavLinkWithQuery = (path: string) => {
    return currentAlert ? `${path}?url=${encodeURIComponent(currentAlert)}` : `${path}`;
  };

  return (
    <Sidebar
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "#233476",
          height: GlobalSizes.fullSize,
        },
      }}
    >
      <Stack alignItems="center" marginRight={GlobalSizes.gap}>
        <Typography fontSize={GlobalSizes.bigFontSize} margin={GlobalSizes.gap} align="center" color="#fff">
          {dashboardState.team?.teamName}
        </Typography>
        <a href="/">
          <Container>
            <img src="/logo_tag.png" alt="brinker logo" width={SidePanelSizes.width} />
          </Container>
        </a>
      </Stack>
      <Menu
        menuItemStyles={{
          button: {
            color: "#fff",
            "&:hover": {
              backgroundColor: "#44499c",
              color: "#fff",
            },
            "&.active": {
              backgroundColor: "#6c73f0",
              color: "#fff",
            },
          },
        }}
      >
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/")}`} />} icon={<Home />}>
          <p className="button_wrapper">
            <span>{labels.home.sideBarTitle}</span>
            <span>{labels.home.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/assets")}`} />} icon={<SecurityIcon />}>
          <p className="button_wrapper">
            <span>{labels.assets.title}</span>
            <span>{labels.assets.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/monitoring")}`} />} icon={<FaHeartbeat />}>
          <p className="button_wrapper">
            <span>{labels.monitoring.title}</span>
            <span>{labels.monitoring.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/flagging")}`} />} icon={<FaFlag />}>
          <p className="button_wrapper">
            <span>{labels.flagging.title}</span>
            <span>{labels.flagging.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/investigation")}`} />} icon={<ScatterPlot />}>
          <p className="button_wrapper">
            <span>{labels.investigation.title}</span>
            <span>{labels.investigation.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/action")}`} />} icon={<FaGavel />}>
          <p className="button_wrapper">
            <span>{labels.takeAction.title}</span>
            <span>{labels.takeAction.subtitle}</span>
          </p>
        </MenuItem>
        <Divider sx={{ my: GlobalSizes.mediumGap }} />
        <MenuItem disabled={true} icon={<Avatar src={props.user?.picture as string}  sx={avatarSize.smallerAvatar} />}>
          <p className="button_wrapper">
            <span>{props.user!.name}</span>
            <span>{userTextEmail}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/team")}`} />} icon={<Groups2Icon />}>
          <p className="button_wrapper">
            <span>{dashboardState.team?.teamName || labels.teamManagement.title}</span>
            <span>{labels.teamManagement.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/ai")}`} />} icon={<PsychologyAltIcon />}>
          <p className="button_wrapper">
            <span>{labels.ai.title}</span>
            <span style={{ whiteSpace: "normal" }}>{labels.ai.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<NavLink to={`${getNavLinkWithQuery("/siem-integration")}`} />} icon={<CrisisAlertIcon />}>
          <p className="button_wrapper">
            <span>{labels.siemIntegration.title}</span>
            <span style={{ whiteSpace: "normal" }}>{labels.siemIntegration.subtitle}</span>
          </p>
        </MenuItem>
        <MenuItem component={<div onClick={() => logoutUser()} />} icon={<LogoutIcon />}>
          <p className="button_wrapper">
            <span>Logout</span>
          </p>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default SidePanel;
