import { useMemo } from "react";
import { GlobalSizes } from "../size";
import { labels, similarityThreshold, stringToColor } from "../labels";
import { Link } from "react-router-dom";
import { MentionType } from "../state";
import InquiriesPieChart from "./charts/InquiriesPieChart";
import InquiriesLineChart from "./charts/InquiriesLineChart";
import EmbeddingsScatterChart from "./charts/EmbeddingsScatterChart";
import EmbeddingsNetworkChart from "./charts/EmbeddingsNetworkChart";
import EmbeddingsNetworkChart2D from "./charts/EmbeddingsNetworkChart2D";
import { useState } from "react";
import {
  Stack,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";



function InquiriesCharts({ mentions }: { mentions: MentionType[] }) {
  const [is3dNetwork, setIs3dNetwork] = useState(true);

  const graphMentions = useMemo(() => mentions.slice(0, 1000), [mentions]);
  const alertsWithAiFilter = useMemo(() => graphMentions.filter((m) => m.ai_filter?.results?.length), [graphMentions]);

  const reducedEmbeddingsAlerts = useMemo(() => graphMentions.filter((m) => m.reduced_embedding), [graphMentions]);

  const lineChartAlerts = useMemo(
    () => alertsWithAiFilter.filter((m) => m.creation_date && new Date(m.creation_date) >= new Date(new Date().setMonth(new Date().getMonth() - 3))),
    [alertsWithAiFilter]
  );

  const mentionsWithSimilarity = useMemo(() => graphMentions.filter(m => (m.similar?.[0]?.score || 0) > similarityThreshold), [graphMentions]);

  if (!alertsWithAiFilter.length && !lineChartAlerts.length && !reducedEmbeddingsAlerts.length) {
    return null;
  }

  return (
    <>
      <Box sx={{ p: GlobalSizes.gap, marginBottom: GlobalSizes.mediumGap }}>
        <Typography variant="h6" gutterBottom>
          <Link to="/ai">{labels.ai.title}</Link>
        </Typography>
      </Box>
      <Stack direction="column" spacing={GlobalSizes.largeGap}>
        {alertsWithAiFilter.length > 0 && <InquiriesPieChart mentions={alertsWithAiFilter} stringToColor={stringToColor} />}
        {lineChartAlerts.length > 0 && <InquiriesLineChart mentions={lineChartAlerts} stringToColor={stringToColor} />}
        {reducedEmbeddingsAlerts.length >= 10 && <EmbeddingsScatterChart mentions={reducedEmbeddingsAlerts} stringToColor={stringToColor} />}
        {mentionsWithSimilarity.length >= 10 &&
          <Box>
            <ToggleButtonGroup
              size="small"
              value={is3dNetwork}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, is3dNetwork: boolean) => {
                console.log({ event, is3dNetwork });
                setIs3dNetwork(is3dNetwork);
              }}
              aria-label="view type"
            >
              <ToggleButton value={true} aria-label="Table View">
                3D
              </ToggleButton>
              <ToggleButton value={false} aria-label="List View">
                2D
              </ToggleButton>
            </ToggleButtonGroup>
            {!is3dNetwork && <EmbeddingsNetworkChart2D mentions={mentionsWithSimilarity} stringToColor={stringToColor} />}
            {is3dNetwork && <EmbeddingsNetworkChart mentions={mentionsWithSimilarity} stringToColor={stringToColor} />}
          </Box>
        }
      </Stack>
    </>
  );
}

export default InquiriesCharts;
