import { MentionType } from "../../state";
import ForceGraph3D from "react-force-graph-3d";
import { Box, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import * as THREE from "three";
import { severity } from "../../labels";
import MentionFocus from "../MentionFocus";
import { similarityThreshold } from "../../labels";
import { useEffect, useState } from 'react';

function EmbeddingsNetworkChart({ mentions, stringToColor }: { mentions: MentionType[]; stringToColor: (str: string) => string }) {
  const [focusedMention, setFocusedMention] = useState<MentionType>();
  const myData = {
    nodes: [] as any[],
    links: [] as any[],
  };

  for (const mention of mentions) {
    const node = {
      id: mention.url,
      name: mention.description_short || mention.title || mention.url,
      image: mention.avatar,
      source: mention.source,
      val: 1,
    };

    myData.nodes.push(node);

    for (const similarMention of mention.similar || []) {
      if (similarMention.score < similarityThreshold) continue;
      if (mentions.findIndex((m) => m.url === similarMention.url) === -1) continue;
      if ((mention.creation_date || 0) > (similarMention.creation_date || 0)) {
        myData.links.push({
          source: similarMention.url,
          target: mention.url,
          similarity: similarMention.score,
        });
      } else {
        myData.links.push({
          source: mention.url,
          target: similarMention.url,
          similarity: similarMention.score,
        });
      }
    }
  }


  const [imgCache, setImgCache] = useState<{ [key: string]: THREE.Texture }>({});

  useEffect(() => {
    // Preload images and cache them
    myData.nodes.forEach(node => {
      if (node.image && !imgCache[node.image]) {
        const img = new THREE.TextureLoader().load(node.image);
        setImgCache(prevCache => ({ ...prevCache, [node.image]: img }));
      }
    });
  }, [mentions, imgCache, myData.nodes]);


  return (
    <Box>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        AI Narrative Network
      </Typography>
      <ForceGraph3D
        graphData={myData}
        width={1000}
        height={500}
        warmupTicks={100}
        enableNodeDrag={false}
        backgroundColor={"#00000000"}
        nodeColor={(node) => stringToColor(node.name)}
        nodeLabel={(node) => `<div style="color: gray; font-weight: bold;">${node.source} - ${node.name}</div>`}
        linkWidth={1}
        linkOpacity={0.8}
        linkLabel={(link) => `<div style="color: gray; font-weight: bold;">${link.similarity.toFixed(2) * 100 + "% similar"}</div>`}
        linkColor={(link) => {
          if (link.similarity > 0.95) return severity.CRITICAL.color;
          if (link.similarity > 0.9) return severity.HIGH.color;
          if (link.similarity > 0.85) return severity.MEDIUM.color;
          return severity.LOW.color;
        }}
        nodeThreeObject={(node) => {
          const group = new THREE.Group();
          // Add PNG sprite to node
          const imgTexture = imgCache[node.image];
          const material = new THREE.SpriteMaterial({ map: imgTexture });
          const sprite = new THREE.Sprite(material);
          sprite.scale.set(20, 20, 1); // Adjust size of the sprite
          sprite.position.set(0, 0, 10); // Move sprite slightly in front of the sphere
          group.add(sprite);

          // Add sphere (ball) to node
          const sphereGeometry = new THREE.SphereGeometry(3); // Adjust size
          const sphereMaterial = new THREE.MeshBasicMaterial({ color: stringToColor(node.source) }); // Ball color
          const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
          sphere.position.set(0, 0, -1); // Move sphere behind the image
          group.add(sphere);
          return group; // Return both the ball and the image sprite
        }}
        onNodeClick={(node) => {
          setFocusedMention(mentions.find((m) => m.url === node.id));
        }}
      />
      <MentionFocus mention={focusedMention} setMention={setFocusedMention} />
    </Box>
  );
}

export default EmbeddingsNetworkChart;
