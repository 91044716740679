import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { GridInitialState } from "@mui/x-data-grid-pro";

export type TweetDataType = {
  author_id: string;
  location?: string;
  conversation_id?: string;
  profile_image_url?: string;
  name?: string;
  protected?: boolean;
  created_at: string;
  description?: string;
  pinned_tweet_id?: string;
  edit_history_tweet_ids?: string[];
  entities?: {
    urls?: { display_url?: string }[];
  };
  id: string;
  in_reply_to_user_id?: string;
  lang: string;
  possibly_sensitive: boolean;
  public_metrics?: {
    bookmark_count?: number;
    impression_count?: number;
    like_count?: number;
    quote_count?: number;
    reply_count?: number;
    retweet_count?: number;
  };
  source: string;
  text?: string;
  url?: string;
  username?: string;
  verified?: boolean;
  verified_type?: string;
};

export type TwitterUserType = {
  created_at: string;
  description: string;
  id: string;
  name: string;
  profile_image_url: string;
  protected: boolean;
  public_metrics: {
    tweet_count: number;
    like_count: number;
    following_count: number;
    listed_count: number;
    followers_count: number;
  };
  url: string;
  username: string;
  verified: boolean;
  verified_type: string;
};

type TweetFullDataType = {
  data: TweetDataType;
  includes: {
    users: TwitterUserType[];
  };
  liking_users?: LikingUsersType;
};

export type LikingUsersType = {
  by_affiliation?: Array<[string, Array<LikingUserType>]>;
  by_date?: Array<[string, Array<LikingUserType>]>;
};

export type LikingUserType = {
  created_at: string;
  description: string;
  engagement_type: string;
  id: string;
  location?: string;
  name: string;
  profile_image_url: string;
  protected: boolean;
  public_metrics: {
    followers_count: number;
    following_count: number;
    like_count: number;
    listed_count: number;
    tweet_count: number;
  };
  username: string;
  verified: boolean;
};

export type TeamType =
  | {
      teamName?: string;
      users?: string[];
      usersInfo?: { [key: string]: { nickname: string; name: string; picture: string } };
    }
  | undefined;

export type AiResultType = {
  content?: {
    booleanAnswer?: boolean;
    explanation?: string;
  };
  key: string;
};

export type AiFilterType = {
  reason: string;
  results?: AiResultType[];
  status?: string;
};

export type MentionType = {
  parent_mention?: string;
  image?: string;
  title?: string;
  category?: string;
  user?: string;
  customer_id?: string;
  description_short?: string;
  url: string;
  avatar?: string;
  impressions?: number;
  creation_date?: number;
  source?: string;
  severity?: string;
  type?: string;
  alertType?: string;
  hidden?: boolean;
  detection_date?: number;
  asset?: string;
  note?: string;
  mitigation_overview?: string;
  mitigation_facebook?: string;
  mitigation_instagram?: string;
  mitigation_twitter?: string;
  mitigation_linkedin?: string;
  mitigation_tiktok?: string;
  mitigation_telegram?: string;
  mitigation_media?: string;
  mitigation_legal?: string;
  mitigation_general?: string;
  urn?: string;
  flagged?: boolean;
  whitelisted?: boolean;
  manual_severity?: string;
  content_image?: string;
  ai_filter?: AiFilterType;
  ai_filter_beta?: AiFilterType;
  flag_date?: number;
  similar?: SimilarMentionType[];
  google_search?: GoogleSearchItemType[];
  collected_manually?: boolean;
  twitter_data?: TweetFullDataType;
  takedown_status?: string;
  full_content?: string;
  emails?: string[];
  phone_numbers?: string[];
  education?: EducationType[];
  work_history?: WorkHistoryType[];
  location_history?: string[];
  last_time_60_enrich?: number;
  last_time_70_enrich?: number;
  reduced_embedding?: number[];
};

type EducationType = {
  display?: string;
};

type WorkHistoryType = {
  display?: string;
};

export interface SimilarMentionType extends MentionType {
  score: number;
}

export type SiemType = {
  splunk?: {
    token: string;
    endpoint: string;
  };
  sentinel?: {
    token: string;
    endpoint: string;
  };
};

export type AccessibleTeamsType = { customerId: string; team: TeamType }[];

export type AssetType = { type: string; creation_date?: number; last_update?: string; last_time_crawled?: { [key: string]: number } };
export type AssetMapType = { [key: string]: AssetType };

type GoogleSearchItemType = {
  link: string;
  title: string;
  snippet: string;
};

export type MitigationType = {
  mitigation_overview?: string;
  mitigation_facebook?: string;
  mitigation_instagram?: string;
  mitigation_twitter?: string;
  mitigation_linkedin?: string;
  mitigation_tiktok?: string;
  mitigation_media?: string;
  mitigation_legal?: string;
  mitigation_general?: string;
};

export type SocialIconMap = {
  [domain: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
};

type Command =
  | "setMentions"
  | "removeAsset"
  | "setAsset"
  | "updateAlert"
  | "getSignedUrl"
  | "removeAlertAttribute"
  | "takedown"
  | "dossier"
  | "mitigation"
  | "setAIQuestion"
  | "removeAIQuestion"
  | "uploadCsv"
  | "setAlertTable"
  | "setSiem"
  | "newTeam"
  | "setTeam"
  | "removeView"
  | "disconnect";

export type ApiRequestData = {
  team?: TeamType;
  mention?: MentionType;
  siem?: SiemType;
  imageId?: string;
  action: Command;
  promptId?: string;
  csv?: string | ArrayBuffer;
  asset?: AssetMapType;
  viewId?: string;
  view?: GridInitialState;
  questionIndex?: string;
  question?: AIQuestionType;
  url?: string;
  attribute?: keyof MentionType;
};

export type AIQuestionType = {
  label: string;
  question: string;
  mitigation?: string;
  link?: string;
  last_update?: number;
  last_updated_by?: string;
  category?: string;
};

export class DashboardState {
  is_active?: boolean;
  customer_id?: string;
  assets?: AssetMapType;
  team?: TeamType;
  saved_views?: { [key: string]: GridInitialState };
  settings?: {
    siem?: SiemType;
    category_sort?: { [key: string]: number };
  };
  ai_questions?: AIQuestionType[];
  accessibleTeams?: AccessibleTeamsType;
  web_socket_connections?: { [key: string]: string };
}

export function entries(obj: {} = {}): [string, any][] {
  return Object.entries(obj);
}
